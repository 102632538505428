.uppy-DragDrop-container {
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 100%;
  font-family: -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", "Segoe UI Symbol", "Segoe UI Emoji", "Apple Color Emoji", Roboto, Helvetica, Arial, sans-serif;
  background-color: #fff;
  border-radius: 7px;
  cursor: pointer;
}
.uppy-DragDrop-container::-moz-focus-inner {
  border: 0;
}
.uppy-DragDrop-container:focus {
  outline: none;
  box-shadow: 0 0 0 3px rgba(18, 105, 207, 0.4);
}

.uppy-DragDrop-inner {
  margin: 0;
  padding: 80px 20px;
  line-height: 1.4;
  text-align: center;
}

.uppy-DragDrop-arrow {
  width: 60px;
  height: 60px;
  margin-bottom: 17px;
  fill: #e0e0e0;
}

.uppy-DragDrop--isDragDropSupported {
  border: 2px dashed #adadad;
}

.uppy-DragDrop--isDraggingOver {
  background: #eaeaea;
  border: 2px dashed #1269cf;
}
.uppy-DragDrop--isDraggingOver .uppy-DragDrop-arrow {
  fill: #939393;
}

.uppy-DragDrop-label {
  display: block;
  margin-bottom: 5px;
  font-size: 1.15em;
}

.uppy-DragDrop-browse {
  color: #1269cf;
  cursor: pointer;
}

.uppy-DragDrop-note {
  color: #adadad;
  font-size: 1em;
}
