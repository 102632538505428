.uppy-ProgressBar {
  /* no important */
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10000;
  width: 100%;
  height: 3px;
  transition: height 0.2s;
}

.uppy-ProgressBar[aria-hidden=true] {
  /* no important */
  height: 0;
}

.uppy-ProgressBar-inner {
  width: 0;
  height: 100%;
  /* no important */
  background-color: #1269cf;
  box-shadow: 0 0 10px rgba(18, 105, 207, 0.7);
  transition: width 0.4s ease;
}

.uppy-ProgressBar-percentage {
  position: absolute;
  top: 50%;
  left: 50%;
  /* no important */
  display: none;
  color: #fff;
  text-align: center;
  transform: translate(-50%, -50%);
}
